import { RefObject } from 'react';

import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { AvatarIconStyle } from '@hh.ru/magritte-ui';
import { GiftOutlinedSize24, CheckCircleOutlinedSize24, ClockOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import Title from 'src/pages/AnonymousEmployer/components/Title';

import Card from 'src/pages/AnonymousEmployer/components/Price/Card';
import CardExp from 'src/pages/AnonymousEmployer/components/Price/CardExp';
import { CardType } from 'src/pages/AnonymousEmployer/components/Price/types';

import styles from './price.less';

interface PriceProps {
    priceRef: RefObject<HTMLDivElement>;
}

const TrlKeys = {
    title: 'anonEmployer.price.title',
    vacancies: {
        title: 'anonEmployer.price.vacancies.standart',
        action: 'anonEmployer.price.vacancies.action',
        price: 'anonEmployer.price.vacancies.price',
        firstOption: 'anonEmployer.price.vacancies.firstOption',
        secondOption: 'anonEmployer.price.vacancies.secondOption',
    },
    candidates: {
        title: 'anonEmployer.price.candidates.title',
        action: 'anonEmployer.price.candidates.action',
        price: 'anonEmployer.price.candidates.price',
        firstOption: 'anonEmployer.price.candidates.firstOption',
        secondOption: 'anonEmployer.price.candidates.secondOption',
    },
};

const Price: TranslatedComponent<PriceProps> = ({ trls, priceRef }) => {
    const resumeCount = useSelector((state) => state.globalStatistics?.resumes) ?? 0;
    const isHH = useIsHhru();
    const isExperiment = useExperiment('anon_employer_new_price_block', isHH);

    return (
        <div className={styles.container} ref={priceRef}>
            <ColumnsWrapper>
                <Column xs="3" s="8" m={isExperiment ? '10' : '9'} l={isExperiment ? '10' : '9'}>
                    <Title>{isExperiment ? 'Выбирайте, как искать' : trls[TrlKeys.title]}</Title>
                    <VSpacing base={12} xs={8} />
                </Column>
            </ColumnsWrapper>
            {isExperiment && (
                <ElementShownAnchor
                    fn={Analytics.sendHHEventElementShown}
                    element={priceRef.current}
                    elementName="AnonymousEmployerPriceBlock"
                    className={styles.cartsContainer}
                >
                    <CardExp
                        type={CardType.Vacancies}
                        withTopTag
                        action="Разместить вакансию"
                        header="Публикуйте вакансии"
                        description="Опишите, кто вам нужен — кандидаты сами с вами свяжутся, вам останется только выбрать"
                        toLink="/auth/employer?backurl=/employer/vacancy/create&from=employer_index_content&hhtmFromLabel=employer_index_content"
                        clickEvent="new_price_vacancy_offer"
                        cartOptions={[
                            {
                                icon: <CheckCircleOutlinedSize24 initial="secondary" />,
                                style: 'secondary' as AvatarIconStyle,
                                text: 'Вы платите только за публикацию, вакансия активна 30 дней',
                            },
                            {
                                icon: <GiftOutlinedSize24 initial="special" />,
                                style: 'special' as AvatarIconStyle,
                                text: 'Бонусом подберём до 15 кандидатов: с ними можно сразу связаться',
                            },
                        ]}
                    />
                    <CardExp
                        type={CardType.Candidates}
                        action="Купить доступ"
                        header="Находите людей в базе резюме"
                        description="Можно не ждать откликов — сразу выбирайте подходящих кандидатов и приглашайте на работу"
                        toLink="/auth/employer?backurl=/price/dbaccess?hhtmFrom=employer_index_content"
                        clickEvent="new_price_db_access_offer"
                        cartOptions={[
                            {
                                icon: <CheckCircleOutlinedSize24 initial="secondary" />,
                                style: 'secondary' as AvatarIconStyle,
                                text: 'Вы платите только за просмотр контактов',
                            },
                            {
                                icon: <ClockOutlinedSize24 initial="secondary" />,
                                style: 'secondary' as AvatarIconStyle,
                                text: 'Контакты открыты до конца действия доступа',
                            },
                        ]}
                    />
                </ElementShownAnchor>
            )}
            {!isExperiment && (
                <div className={styles.cartsContainer}>
                    <Card
                        type={CardType.Vacancies}
                        action={trls[TrlKeys.vacancies.action]}
                        header={trls[TrlKeys.vacancies.title]}
                        cartOptions={[trls[TrlKeys.vacancies.firstOption], trls[TrlKeys.vacancies.secondOption]]}
                        priceTitle={trls[TrlKeys.vacancies.price]}
                        toLink="/auth/employer?backurl=/employer/vacancy/create&from=employer_index_content&hhtmFromLabel=employer_index_content"
                        clickEvent="new_price_vacancy_offer"
                    />
                    <Card
                        type={CardType.Candidates}
                        action={trls[TrlKeys.candidates.action]}
                        header={trls[TrlKeys.candidates.title]}
                        cartOptions={[
                            format(trls[TrlKeys.candidates.firstOption], {
                                '{0}': NumberFormatter.format(resumeCount.toString()),
                            }),
                            trls[TrlKeys.candidates.secondOption],
                        ]}
                        priceTitle={trls[TrlKeys.candidates.price]}
                        toLink="/auth/employer?backurl=/price/dbaccess?hhtmFrom=employer_index_content"
                        tiny
                        clickEvent="new_price_db_access_offer"
                    />
                </div>
            )}
        </div>
    );
};

export default translation(Price);
